import React, { useEffect, useState } from 'react';
import { Checkbox, Flex, Modal } from 'antd';
import DefaultButton from '@src/components/ui-kit/buttons/DefaultButton';
import { ZepetoInstructionsModalContent } from '@src/components/menu/zepetoInstructionsModal';

interface Props {
  isOpen: boolean;
  setIsOpen: Function;
}

export default function UnityInstructionModal({ isOpen, setIsOpen }: Props) {
  const [show, setIsShow] = useState(true);
  const [isNotShowChecked, setIsNotShowChecked] = useState(false);

  function closeModal() {
    if (isNotShowChecked) {
      window.localStorage.setItem('not_show_unity_instructions', 'true');
    }
    setIsOpen(false);
  }

  useEffect(() => {
    if (window) {
      const notShow = window.localStorage.getItem(
        'not_show_unity_instructions'
      );
      if (!notShow) {
        return;
      }
      if (notShow === 'true') {
        setIsShow(false);
      }
    }
  }, []);

  if (!show) {
    return <></>;
  }

  return (
    <Modal
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      footer={false}
      destroyOnClose
    >
      <div>
        {ZepetoInstructionsModalContent}
        <Flex align='center' justify='end' gap={10} style={{ marginTop: 40 }}>
          <Checkbox onChange={(e) => setIsNotShowChecked(e.target.checked)}>
            Don't show me this again
          </Checkbox>
          <DefaultButton type='primary' onClick={closeModal}>
            <span style={{ color: '#fff' }}>Close</span>
          </DefaultButton>
        </Flex>
      </div>
    </Modal>
  );
}
