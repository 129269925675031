import React from 'react';
import { Button } from 'antd';
import { MouseEventHandler } from 'react';
import styles from './default-button.module.css';

const style = {
  primary: styles.primaryButton,
  default: styles.defaultButton,
  secondary: styles.secondaryButton,
  secondaryRed: styles.secondaryRedButton,
};

type Props = {
  icon?: React.ReactNode;
  children?: React.ReactElement[] | React.ReactElement;
  onClick?: MouseEventHandler<HTMLElement>;
  style?: React.CSSProperties | undefined;
  type?: 'primary' | 'secondary' | 'secondaryRed';
};

const DefaultButton: React.FC<Props> = (props) => {
  return (
    <Button
      className={props.type ? style[props.type] : 'default'}
      icon={props.icon}
      onClick={props.onClick}
      style={props.style}
    >
      {props.children}
    </Button>
  );
};

export default DefaultButton;
