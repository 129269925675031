import { Button, Divider, Flex } from 'antd';
import { CDN_MARKETPLACE } from '../../../components/scene/assets/baseUrl';
import { ZepetoTechTextLogo } from './ZepetoTechTextLogo';
import { memo } from 'react';
import { useRouter } from 'next/router';

const ZepetoLogo = ({ styles }) => {
  const router = useRouter();
  function redirectMain() {
    router.push('/');
  }
  return (
    <Flex align='center' gap={12} className={styles.zepeto}>
      <Flex gap={10} className={styles.zepetoWrapper} onClick={redirectMain}>
        <div className={styles.logoWrapper}>
          <img
            src={`${CDN_MARKETPLACE}/assets/logos/zepeto-logo.svg`}
            alt='ZEPETO'
            style={{ objectFit: 'contain', height: '15px' }}
            className={styles.zepetoLogo}
          />
        </div>
        <div className={styles.webBuilder}>Web builder</div>
      </Flex>
      <Divider type='vertical' className={styles.divider} />
      <Flex vertical gap={10} className={styles.ozoneBlock}>
        <span className={styles.zepetoText}>Powered By</span>
        <Flex align='center' gap={4}>
          <img
            src={`${CDN_MARKETPLACE}/assets/logos/ozone-black-circle.png`}
            style={{ width: '36px', height: '36px' }}
            alt='OZONE'
          />
          {ZepetoTechTextLogo}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default memo(ZepetoLogo);
