import { useState, useEffect } from 'react';

type ScreenSize = {
  width: number | undefined;
  height: number | undefined;
};
export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  } as ScreenSize);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }

      window.addEventListener('resize', handleResize);

      // Call handler right away so state gets updated with initial window size
      handleResize();

      return () => window.removeEventListener('resize', handleResize);
    }
    return undefined;
  }, []);
  return windowSize;
}
