import React, { useCallback } from 'react';
import { Divider, Tooltip, message, Flex } from 'antd';
import DefaultButton from '../ui-kit/buttons/DefaultButton';
import { NEXT_PUBLIC_URL } from 'util/constants';
import { trackUmamiEvent } from 'util/helpers';
import ChangeSceneAlias from './nav-editor-actions/ChangeSceneAlias';
import { memo } from 'react';
import styles from '../navigation-bar/navigation-bar.module.css';

interface Props {
  isSignIn: boolean;
  ref1?: any;
  ref2?: any;
  ref3?: any;
  setIsSaveScene: Function | undefined;
  sceneId: string | undefined;
}

const NavigationButtons: React.FC<Props> = ({
  isSignIn,
  sceneId,
  setIsSaveScene,
  ref1,
  ref2,
  ref3,
}) => {
  const copy = () => {
    trackUmamiEvent('Editor: Share scene');
    navigator.clipboard.writeText(`${NEXT_PUBLIC_URL}scene/${sceneId}`);
    message.success('Link copied');
  };

  function onPreviewScene() {
    trackUmamiEvent('Editor: Preview scene');
  }

  const saveScene = useCallback(() => {
    if (setIsSaveScene) {
      setIsSaveScene(true);
      trackUmamiEvent('Editor: Save scene');
    }
  }, [setIsSaveScene]);

  return (
    <>
      {isSignIn && (
        <Flex align='center' className={styles.sceneNameWrapper}>
          <ChangeSceneAlias sceneId={sceneId} />
          <Divider type='vertical' className={styles.divider} />
        </Flex>
      )}
      {isSignIn && (
        <>
          <Tooltip title='Preview'>
            <a
              href={`/scene/${sceneId}`}
              target='_blank'
              ref={ref1}
              rel='noreferrer'
              style={{ marginRight: isSignIn ? 5 : 0 }}
            >
              <DefaultButton
                type='secondary'
                style={{ minHeight: 32 }}
                onClick={onPreviewScene}
              >
                <img
                  src={'/preview-icon.svg'}
                  alt='icon'
                  width={17}
                  height={17}
                  style={{ marginTop: -2 }}
                />
              </DefaultButton>
            </a>
          </Tooltip>
          <Tooltip title='Share'>
            <div ref={ref2}>
              <DefaultButton
                type='secondary'
                style={{ minHeight: 32 }}
                onClick={copy}
              >
                <img
                  src={'/share-icon.svg'}
                  alt='icon'
                  width={17}
                  height={17}
                  style={{ marginTop: -2 }}
                />
              </DefaultButton>
            </div>
          </Tooltip>
          <Divider type='vertical' className={styles.divider} />
        </>
      )}
      <div style={{ marginLeft: isSignIn ? 0 : 160 }} ref={ref3}>
        <DefaultButton
          type='primary'
          style={{ minHeight: 32 }}
          onClick={saveScene}
        >
          <img
            src={'/save-icon.svg'}
            alt='icon'
            width={17}
            height={17}
            style={{ marginTop: -2, marginRight: 5 }}
          />
          <span style={{ color: '#fff' }}>Save</span>
        </DefaultButton>
      </div>

      <Divider type='vertical' className={styles.divider} />
    </>
  );
};

export default memo(NavigationButtons);
