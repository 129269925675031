import React from 'react';
import { Modal, Button } from 'antd';
import { trackUmamiEvent } from 'util/helpers';
import SignIn from '@ozoneuniverse/ui-components/build-pkg/src/components/auth/login/Login';
import styles from './login.module.css';

export default function LoginModal({ isOpen, setIsOpen }) {
  function openModal() {
    setIsOpen(true);
    trackUmamiEvent('Editor: Open Login modal');
  }

  return (
    <div>
      <Button onClick={openModal}>Login</Button>
      <Modal open={isOpen} onCancel={() => setIsOpen(false)} footer={false}>
        <div className={styles.wrapper}>
          <SignIn
            redirectUrl={''}
            onSuccessLogin={() => {
              setIsOpen(false);
              window.location.reload();
            }}
            enableRedirect={false}
          />
        </div>
      </Modal>
    </div>
  );
}
