import React, { memo, useState } from 'react';
import { Button, Drawer, List } from 'antd';
import { signOut } from 'next-auth/react';
import { SIGN_IN_URL } from 'util/constants';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import { useWindowSize } from '@src/components/hooks/useWindowDimensions';
import Link from 'next/link';
import styles from './menu-drawer.module.css';

const MenuDrawerComponent = ({ isSignIn }) => {
  const [visible, setVisible] = useState(false);
  const size = useWindowSize();

  return (
    <div className={styles.wrapper}>
      {visible ? (
        <Button
          className={styles.menuButton}
          icon={<CloseOutlined className={styles.menuIcon} />}
          onClick={() => setVisible(false)}
        ></Button>
      ) : (
        <Button
          className={styles.menuButton}
          icon={<MenuOutlined className={styles.menuIcon} />}
          onClick={() => setVisible(true)}
        ></Button>
      )}
      {(size?.width as number) <= 700 && (
        <Drawer
          placement='bottom'
          onClose={() => {
            setVisible(false);
          }}
          headerStyle={{ display: 'none' }}
          height={'100%'}
          style={{ marginTop: 80 }}
          open={visible}
        >
          <List
            size='small'
            itemLayout='horizontal'
            className={styles.menuList}
          >
            {isSignIn && (
              <>
                <List.Item>
                  <Link href='/user/studio'>Studio</Link>
                </List.Item>
                <List.Item>
                  <Link href='/user/user-profile'>Your Profile</Link>
                </List.Item>
                <List.Item>
                  <a
                    onClick={() => {
                      signOut();
                    }}
                  >
                    Sign Out
                  </a>
                </List.Item>
              </>
            )}
            {!isSignIn && (
              <>
                <List.Item>
                  <Link href={SIGN_IN_URL}>Sign In / Sign Up</Link>
                </List.Item>
              </>
            )}
          </List>
        </Drawer>
      )}
    </div>
  );
};

export const MenuDrawer = memo(MenuDrawerComponent);
