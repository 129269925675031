import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { QuestionCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { isDev, NEXT_PUBLIC_URL } from 'util/constants';
import {
  useGetSceneAliasQuery,
  useFindAliasSceneLazyQuery,
  useUpdateAliasSceneMutation,
  GetSceneAliasDocument,
} from '@src/components/generated/graphql';
import type { MenuProps } from 'antd';
import {
  Button,
  Modal,
  Form,
  Input,
  Tooltip,
  Spin,
  notification,
  Typography,
} from 'antd';
import styles from './change-scene-alias.module.css';

const { Paragraph } = Typography;
const SCENE_URL = isDev
  ? NEXT_PUBLIC_URL + '/scene/'
  : NEXT_PUBLIC_URL + 'scene/';
const inputLoading = <Spin indicator={<LoadingOutlined />} spinning />;

const openNotification = (type, msg, desc) => {
  notification[type]({
    message: msg,
    description: desc,
  });
};

function AliasModal({ open, close, sceneId, alias }) {
  const [updateLoading, setUpdateLoading] = useState(false);
  const [showPreview, setIsShowPreview] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [checkLoading, setCheckLoading] = useState(false);
  const [isAliasFree, setIsAliasFree] = useState(false);
  const [inputSuffix, setInputSuffix] = useState((<></>) as any);
  const [inputStatus, setInputStatus] = useState(undefined as any);
  const [input, setInput] = useState('');
  const [checkAliasQuery] = useFindAliasSceneLazyQuery();
  const [updateAlias] = useUpdateAliasSceneMutation();

  const checkAlias = debounce(async (value) => {
    setCheckLoading(false);
  }, 3000);

  const onChange = async (input) => {
    setDisableButton(true);
    setCheckLoading(true);
    setIsAliasFree(false);
    setInputStatus(undefined);
    checkAlias.cancel();
    const value = input.target.value;

    if (value.length <= 1) {
      setInputStatus(undefined);
      setInputSuffix(<></>);
      setIsShowPreview(false);
      setIsAliasFree(false);
      setInput('');
      return;
    }

    setInputSuffix(inputLoading);
    setInput(value);
    setIsShowPreview(true);
    checkAlias(value);
  };

  async function onFinish(values) {
    setUpdateLoading(true);

    const res = await updateAlias({
      variables: {
        where: {
          sceneId: sceneId,
          alias: values.alias,
        },
      },
      refetchQueries: [GetSceneAliasDocument],
    });

    if (res.data?.updateAliasScene.status === 'success') {
      openNotification('success', 'Success', 'Successfully updated alias!');
    } else {
      openNotification(
        'error',
        'Error',
        'Failed to update alias, please try again or contact support.'
      );
    }

    setUpdateLoading(false);
  }

  useEffect(() => {
    async function fetch() {
      const res = await checkAliasQuery({
        variables: { where: { alias: input } },
        fetchPolicy: 'no-cache',
      });

      if (!res.loading) {
        setInputSuffix(<></>);
      }

      if (res.data?.findAliasScene.exists) {
        setInputStatus('error');
      } else {
        setIsAliasFree(true);
        setDisableButton(false);
      }
    }

    if (!checkLoading && input.length) {
      fetch();
    }
  }, [checkLoading]);

  return (
    <Modal
      open={open}
      destroyOnClose
      onCancel={() => {
        if (updateLoading) {
          return;
        }
        setInputStatus(undefined);
        setIsAliasFree(false);
        setIsShowPreview(false);
        setInput('');
        close();
      }}
      width={480}
      title={<span className={styles.titleModal}>Scene Alias</span>}
      footer={false}
      bodyStyle={{ borderTop: '0.3px solid #b3b3b38e' }}
    >
      {!alias && (
        <div className={styles.info}>
          <div className={styles.alertTitle}>Your default scene URL:</div>
          <div className={styles.alertURL}>
            <Paragraph
              copyable
              className={styles.paragraph}
              style={{ margin: 0 }}
            >
              {`${SCENE_URL}${sceneId}`}
            </Paragraph>
          </div>
        </div>
      )}
      {alias && (
        <div className={styles.info}>
          <div className={styles.alertTitle}>Original ID:</div>
          <div className={styles.alertURL}>
            <Paragraph
              copyable
              className={styles.paragraph}
              style={{ margin: 0 }}
            >
              {sceneId}
            </Paragraph>
          </div>
          <div className={styles.alertTitle} style={{ marginTop: 10 }}>
            Your current scene URL:
          </div>
          <div className={styles.alertURL}>
            <Paragraph
              copyable
              className={styles.paragraph}
              style={{ margin: 0 }}
            >
              {`${SCENE_URL}${alias}`}
            </Paragraph>
          </div>
        </div>
      )}
      <div className={styles.formContainer}>
        <Form layout='vertical' onFinish={onFinish}>
          <Form.Item
            name='alias'
            initialValue={alias ? alias : undefined}
            label={
              <span className={styles.label}>
                Your new scene URL{' '}
                <Tooltip title='You can set a unique URL for your scene. Once you change it, links to your old URL will no longer work.'>
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
            rules={[
              {
                required: true,
                message: `Please Input alias!`,
              },
            ]}
          >
            <Input
              style={{ height: 48 }}
              onChange={onChange}
              className={isAliasFree ? styles.greenInput : undefined}
              status={inputStatus}
              suffix={inputSuffix}
            />
          </Form.Item>
          {inputStatus === 'error' && (
            <div className={styles.error}>
              This alias in use, please use other alias
            </div>
          )}
          {isAliasFree && (
            <div className={styles.success}>Alias is available</div>
          )}
          {showPreview && (
            <div>
              <span className={styles.previewLabel}>Preview:</span>
              <span className={styles.previewBaseUrl}>{`${SCENE_URL}`}</span>
              <span className={styles.previewInput}>{input}</span>
            </div>
          )}
          <div className={styles.buttons}>
            <Button
              data-cy='cancel-test-button'
              className={styles.button}
              onClick={close}
              disabled={updateLoading}
            >
              Cancel
            </Button>
            <Button
              data-cy='change-test-button'
              className={styles.button}
              type='primary'
              htmlType='submit'
              disabled={disableButton || updateLoading}
            >
              {updateLoading ? inputLoading : 'Change'}
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

function ChangeSceneAlias({ sceneId }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data } = useGetSceneAliasQuery({
    variables: {
      where: {
        id: sceneId ?? '',
      },
    },
    fetchPolicy: 'network-only',
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <a onClick={() => setIsModalOpen(true)}>Change scene alias</a>,
    },
  ];

  return (
    <>
      <Button onClick={() => setIsModalOpen(true)} type='default'>
        Scene Name
      </Button>
      <AliasModal
        open={isModalOpen}
        close={() => setIsModalOpen(false)}
        sceneId={sceneId}
        alias={data?.getSceneAlias.alias}
      />
    </>
  );
}

export default ChangeSceneAlias;
